section.section-burst-icons.index {
    background-color: $color-2;
    padding: 30px 0;


        div.icon-bursts {
            display: flex;
            justify-content: space-around;
            align-items: center;
            transition: .5s;
            flex-wrap: wrap;

                ////Unique burtst
                div.icon-burst {
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    @media screen and (max-width: 991px) {
                        transition: .5s;
                        height: auto;
                        margin: 30px auto;
                        &:nth-child(2){
                          border-right: none;
                          border-left: none;
                        }
                    }


                    div.element-burst {
                        display: flex;
                        flex-direction: row-reverse;
                        justify-content: center;
                        align-items: center;
                        width: 100%;
                        @media screen and (max-width: 600px){
                          flex-direction: column;
                          transition: .5s;
                        }
                        transition: .5s;
                        div.content-img {
                            height: 160px;
                            width: 160px;
                            padding: 20px;
                            border-radius: 50%;
                            border: 3px solid $color-white;
                            display: flex;
                            background-color: $color-1;
                            align-items: center;
                            justify-content: center;
                            position: relative;
                            img {
                                max-width: 114px;
                            }
                        }
                        &:hover {
                            transition: .5s;
                            div.content-img {
                              border: 3px solid $color-1;
                              background-color: $color-white;
                                img:nth-child(1) {
                                    opacity: 0;
                                    transition: .5s;
                                }
                                img:nth-child(2) {
                                    transition: .5s;
                                    max-width: 114px;
                                    //position: absolute;
                                    opacity: 1;
                                }
                            }
                            div.text-bloc {
                                h3 {
                                    color: $color-white;
                                    transition: .5s;
                                }
                            }
                        }

                    }

                    a {
                        display: flex;
                        flex-direction: column;
                        justify-content: space-around;
                        align-items: center;
                        div.content-img {
                            display: flex;
                            justify-content: center;
                            align-items: center;

                            img:nth-child(1) {
                                opacity: 1;
                                transition: .5s;
                            }
                            img:nth-child(2) {
                                transition: .5s;
                                position: absolute;
                                opacity: 0;
                            }
                        }

                        div.text-bloc {
                            marker-start: 15px;
                            h3 {
                                color: $color-1;
                                font-weight: 500;
                                padding: 0 10px;
                                font-size: $font-size-28;
                                text-align: right;
                                text-transform: none;
                                margin-top: 15px;
                                transition: .5s;
                            }
                        }
                    }
                }

        }

}