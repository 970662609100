// IMPORT ADDONS
@import '../vendors/bootstrap/css/bootstrap';
@import '../vendors/font-awesome/font-awesome';

/* purgecss start ignore */
// @import '../vendors/owl-carousel2/owl.carousel';
@import '../vendors/magnific-popup/magnific-popup';
// @import '../vendors/cube-portfolio/cubeportfolio';
@import 'styles/alertify';
/* purgecss end ignore */

// IMPORT CUSTOMIZATION & RESET (order is important)
@import 'core/reset';
@import 'variables';
@import 'core/html';
@import 'utilities/functions';

// *******************
// Load basic classes (besoin de rester ici à la fin)
@import 'styles/fonts'; // utility classes for fonts
@import 'utilities/spacing'; // utility classes for spacing
@import 'utilities/flex'; // utility classes for flex box
@import 'utilities/responsive';
@import 'utilities/animation';

@import 'styles/colors'; // utility classes for colors
@import 'styles/hover';
@import 'styles/typography';
@import 'styles/buttons';

/* GENERAL TEMPLATE */
body {
    background-color: $bg-color-body;

    // to fix parallax bug on iphone...
    .parallax {
        background-attachment: fixed;
        @media screen and (max-width: $size-xs-max) {
            background-attachment: scroll;
        }
    }
}

/** Add this class to any anchor tag so it clear the fixed menu height (used in forms) */
.jumptarget::before {
    content: "";
    display: block;
    height: $size-nav-height; /* fixed header height*/
    margin: -$size-nav-height 0 0; /* negative fixed header height */
}

/** Adds a shadow to the div element **/
.shadow {
    position: relative;

    &::after {
        content: '';
        z-index: 0;
        width: 100%;
        top: 100%;
        height: 40px;
        position: absolute;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    }
}


/*****************************************************/
/* Menu (to use with /modules/module-menu.php)
/* Or /modules/module-menu-centre.php
/* Or /modules/module-menu-etage.php
 */
/*****************************************************/
@import 'modules/menu';
// @import 'menu-centre';
// @import 'menu-etage';


/*****************************************************/
/* Footer (to use with /modules/module-footer.php)
/*****************************************************/
@import 'styles/footer';

/*****************************************************/
/* Sections (to use with /sections/section-*-*.php)
/*****************************************************/
//@import 'sections/section-header-page';

//@import 'sections/section-text-image';

@import 'sections/section-bursts-icons';

/*****************************************************/
/* Modules (to use with /modules/module-*-*.php)
/*****************************************************/
@import 'modules/module-slideshow';
@import 'modules/module-header';
@import 'modules/module-soumission';




/*******************************************************************************
* ACCUEIL
*******************************************************************************/

section.section-01-parallax {
    padding: 130px 10%;
    background-image: url(../images/accueil_section01_bg.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    div.container-fluid.row {
        & > div {
            width: 50%;
            @media screen and (max-width: $size-xs-max){
              width: 100%;
            }
        }
    }
    h4 {
        color: $color-1;
        font-size: $font-size-26;
        font-weight: 500;
    }
    p {
        line-height: 1.3;
        color: $color-white;
    }
}

section.section-03 {
    background-color: $color-3;
    div.txt {
        background-color: $color-2;
        padding: 30px;
        h3 {
            color: $color-white;
            font-weight: 800;
            font-size: $font-size-28;
        }
        ul {
            //padding-left: 20px;
            li {
                list-style: none;
                position: relative;
                &::before {
                  content: '•';
                  font-size: 35px;
                  position: absolute;
                  left: -30px;
                  top: -13px;
                }
                color: $color-white;
                font-size: $font-size-20;
                line-height: 1.2;
                padding-bottom: 15px;
            }
        }
    }
}

/*******************************************************************************
* À PROPOS
*******************************************************************************/

#section-principal {
    background: #000e25;
    padding: 50px 10%;
    .section-title {
        background: #b3bd01;
        width: 30%;
        padding: 10px 0;
        text-align: center;
        h2 {
            color: $color-black;
            font-weight: 800;
            font-size: $font-size-34;
            text-transform: uppercase;
        }
    }
    .section-text {
        display: flex;
        padding-top: 50px;
        h3 {
            font-weight: 500;
            font-size: $font-size-34;
            color: #b3be0e;
            text-transform: initial;
        }
        .left-side {
            width: 45%;
            margin-right: 5%;
            p {
                font-weight: normal;
                font-size: $font-size-28;
                color: $color-white;
                margin: 0;
                line-height: 1.3;
                padding: 10px 0 20px 0;
            }
        }
        .right-side {
            width: 50%;
            padding-top: 5.5%;
            .section-logo {
                display: flex;
                align-items: center;
                justify-content: center;
                padding-bottom: 30px;
                .logo {
                    width: 30%;
                }
            }
            .list {
                display: flex;
                justify-content: center;
                padding-top: 100px;
                ul {
                    list-style-image: url(../images/apropos_section01_point_vert.jpg);
                    padding-left: 20px;
                    li {
                        font-weight: bold;
                        font-size: $font-size-28;
                        color: #b3be0e;
                        line-height: 1.5;
                        padding-bottom: 5px;
                        padding-left: 40px;
                    }
                } 
            }
        }
    }
}

/*******************************************************************************
* DRAGAGE SPÉCIALISÉ
*******************************************************************************/

section.section-03.dragage {
    padding: 50px 10%;
    .section-title {
        background: #b3bd01;
        width: 30%;
        padding: 10px 0;
        text-align: center;
        h2 {
            color: $color-black;
            font-weight: 800;
            font-size: $font-size-34;
            text-transform: uppercase;
        }
    }
    .dragage {
        h3 {
            font-weight: 500;
            font-size: $font-size-34;
            color: #b3be0e;
            text-transform: initial;
        }
        p {
            font-weight: normal;
            font-size: $font-size-28;
            color: $color-white;
            margin: 0;
            line-height: 1.3;
        }
        p.first {
            padding: 15px 0 25px 0;
        }
    }
}

#section-background {
    background: url("../images/apropos_section03_bg.jpg") no-repeat;
    background-size: cover;
    min-height: 875px;
    padding: 100px 10%;
    .text {
        padding-bottom: 51px;
        p {
            font-weight: normal;
            font-size: $font-size-28;
            color: $color-white;
            margin: 0;
            line-height: 1.3;
        }
    }
    .text.smallw {
        width: 50%;
    }
}

/*******************************************************************************
* POMPAGE SPÉCIALISÉ
*******************************************************************************/

#section-principal.propos {
    .section-text {
        .left-side {
            padding-top: 2%;
        }
    }
    .text {
        padding-top: 50px;
        p {
            font-weight: normal;
            font-size: $font-size-28;
            color: $color-white;
            margin: 0;
            line-height: 1.3;
        }
    }
}

#section-background.pompage {
    background: url(../images/pompage_section02_bg.jpg) no-repeat;
    min-height: 650px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 22%;
    h3 {
        font-weight: bold;
        font-size: $font-size-34;
        color: #fff;
        text-transform: initial;
    }
}

/*******************************************************************************
* LOCATION D'ÉQUIPEMENTS
*******************************************************************************/

#section-principal.equipements {
    .text-intro {
        padding: 35px 0;
        p {
            font-weight: normal;
            font-size: $font-size-28;
            color: $color-white;
            margin: 0;
            line-height: 1.3;
        }
    }
    .section-equipements {
        .realisations {
            display: flex;
            flex-wrap: wrap;
            a.item {
                width: 33.3333%;
                padding: 0 25px;
                .bloc {
                    h3 {
                        font-weight: 500;
                        font-size: $font-size-34;
                        color: #b4be02;
                        padding-bottom: 5px;
                    }
                    p, li {
                        font-weight: normal;
                        font-size: $font-size-28;
                        color: $color-white;
                        margin: 0;
                        line-height: 1.3;
                    }
                    ul {
                        padding-left: 30px;
                    }
                    .img-responsive {
                        padding-bottom: 10px;
                    }
                }
            }
        }
    }
}

/*******************************************************************************
* EMPLOI
*******************************************************************************/

#section-principal.equipements {
    .section-emplois {
        section.emplois-liste {
            h3 {
                font-weight: 600;
                font-size: $font-size-28;
                color: #b4be02;
                padding-bottom: 20px;
            }
            .item-poste.emploi {
                width: 45%;
                margin-right: 5%;
                padding: 30px 0 50px 0;
                h4 {
                    font-weight: 600;
                    font-size: $font-size-28;
                    color: $color-white;
                    padding-bottom: 20px;
                }
                p, li {
                    font-weight: normal;
                    font-size: $font-size-22;
                    color: $color-white;
                    margin: 0;
                    line-height: 1.5;
                }
                ul {
                    padding-left: 45px;
                    li {
                        text-transform: initial;
                        padding-bottom: 5px;
                    }
                }
                button {
                    background: #fff !important;
                    border: none;
                    color: #000;
                    font-weight: 600;
                    font-size: $font-size-32;
                    padding: 0;
                    width: 50%;
                    margin-top: 25px;
                    &:hover {
                        background: #b4be02 !important;
                    }
                }
            }
        }
    }
}

section#section-form {
    padding: 50px 0;
    h3 {
        font-weight: bold;
        font-size: $font-size-34;
        color: $color-black;
        text-align: center;
    }
}




/*******************************************************************************
* COORDONNÉES
*******************************************************************************/

#section-principal.coordonnees {
    .text-intro {
        padding: 35px 0;
        p {
            font-weight: normal;
            font-size: $font-size-28;
            color: $color-white;
            margin: 0;
            line-height: 1.3;
        }
    }
    .bloc {
        display: flex;
        .left-side {
            width: 45%;
            .info {
                display: flex;
                padding-bottom: 50px;
            }
            .icon {
                width: 15%;
            }
            .text {
                h3.name {
                    color: #b4be02;
                }
                h3 {
                    font-weight: 500;
                    font-size: $font-size-34;
                    color: #fff;
                }
            }
        }
        .right-side {
            width: 55%;
        }
    }
    .bloc.first {
        padding-bottom: 100px;
    }
    .bloc.email {
        padding-top: 30px;
    }
}



/*******************************************************************************
* MOBILE OVERRIDES FOR ALL SECTIONS
*******************************************************************************/

@media screen and (max-width: 1550px) {

#section-principal.equipements .section-equipements .realisations a.item .bloc p, #section-principal.equipements .section-equipements .realisations a.item .bloc li {
    font-size: 1.25rem;
}
}

@media screen and (max-width: 1500px) {
   
#section-principal.coordonnees .bloc .left-side .text h3 {
    font-size: 1.5rem;
}
#section-principal.coordonnees .bloc .left-side .icon {
    width: 18%;
}
}

@media screen and (max-width: 1470px) {

.font-nav-items, nav.module-menu .menu-navbar .wrapper.right .main-menu > ul li > a, nav.module-menu .menu-navbar .wrapper.right .main-menu > ul li > .menu-title, nav.module-menu #menu-mobile ul li > a, nav.module-menu #menu-mobile ul li > .menu-title {
    font-size: 0.95rem;
}
}

@media screen and (max-width: 1365px) {

nav.module-menu .menu-navbar .wrapper.left .logo {
    max-width: 400px;
}
}

@media screen and (max-width: $size-md-max) {
   
#section-principal {
    padding: 50px 5%;
}
#section-background.pompage {
    min-height: 450px;
}
#section-principal.equipements .section-equipements .realisations a.item {
    width: 50%;
    padding: 25px;
}
}

@media screen and (max-width: 1024px) {
  
#section-principal.coordonnees .bloc .left-side {
    width: 55%;
}
#section-background {
    padding: 50px 10%;
}
nav.module-menu .menu-navbar .wrapper.left .logo {
    max-width: 290px;
}
}

@media screen and (max-width: $size-sm-max) {
  
#section-principal.coordonnees .bloc .left-side .icon {
    width: 27%;
}
#section-principal .section-text {
    flex-direction: column;
}
#section-principal.propos .section-text .left-side {
    padding-top: 0;
    width: 100%;
    margin-right: 0;
}
#section-principal .section-text .right-side {
    width: 100%;
    padding-top: 50px;
}
#section-principal .section-text .right-side .list {
    padding-top: 50px;
}
#section-background.pompage {
    padding: 0 5%;
}
section.section-03.dragage .section-title {
    width: 50%;
}
section.section-03.dragage {
    padding: 50px 5%;
}
}

@media screen and (max-width: $size-xs-max) {
 
nav.module-menu .menu-navbar .wrapper.left .logo {
    max-width: 250px;
} 
#section-principal .section-title {
    width: 70%;
}
#section-principal.coordonnees .bloc {
    flex-direction: column;
}
#section-principal.coordonnees .bloc .left-side {
    width: 100%;
    padding-bottom: 25px;
}
#section-principal.coordonnees .bloc .left-side .bloc.tel {
    display: flex;
}
#section-principal.coordonnees .bloc .right-side {
    width: 100%;
}
#section-principal.coordonnees .bloc .left-side .info {
    padding-bottom: 25px;
}
#section-principal.coordonnees .bloc.first {
    padding-bottom: 50px;
}
#section-principal .section-text .right-side .section-logo .logo {
    width: 50%;
}
#section-background.pompage {
    min-height: 350px;
}
#section-background .text.smallw {
    width: 100%;
}
#section-background .text {
    padding-bottom: 25px;
}
#section-background {
    min-height: 765px;
}
#section-principal.equipements .section-equipements .realisations a.item {
    width: 100%;
    padding: 0 0 25px 0;
}
.section-emplois section.emplois-liste .container-fluid.flex.row {
    flex-direction: column;
}
#section-principal.equipements .section-emplois section.emplois-liste .item-poste.emploi {
    width: 100%;
    margin-right: 0;
    padding: 30px 0;
}
#section-principal.equipements .section-emplois section.emplois-liste .item-poste.emploi button {
    width: 55%;
}
}
